<template>
  <div>
    <div class="relative" @click="show = true">
      <slot :checked-label="checkedLabel">
        <!-- 默认内容 -->
      </slot>
    </div>
    <van-popup v-model:show="show" position="bottom" closeable safe-area-inset-bottom round>
      <div class="px-4 py-3 text-center align-middle text-lg font-500">
        {{ t('title') }}
      </div>
      <van-checkbox-group v-model="tagList" class="w-full" icon-size="1.5rem">
        <template v-for="option in TicketOptions" :key="option.tagTypeName">
          <div class="px-4 pb-2 pt-5 text-base text-[#121212] font-500">
            {{ t(option.tagTypeName) }}
            <span v-if="option.tagTypeName === 'Ticket Types'" class="text-[#777474]">
              (optional)
            </span>
          </div>
          <BaseAZCheckBox
            v-for="item in option.attrTagList"
            :key="item.tagId"
            :name="item.tagId"
            :disabled="isDisabeld(option.tagTypeName, item)"
            class="box-border w-full flex items-center p-4"
          >
            <span class="ml-2 text-sm text-[#121212] font-500">{{ item.tagName }}</span>
            <!-- <span v-if="item.desc" class="font-500 text-sm text-[#777474] ml-1">{{ item.desc }}</span> -->
          </BaseAZCheckBox>
        </template>
      </van-checkbox-group>
      <div class="p-4">
        <van-button round block color="#000000" @click="onConfirm">
          {{ t('apply') }}
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

const props = defineProps({
  onlyRead: {
    type: Boolean,
    default: false,
  },
  isDetail: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const show = ref(false)

const commonStore = useCommonStore()
const filterConditionsStore = useFilterConditionsStore()

const tagList = ref<number[]>([])
const TicketOptions = computed(() => commonStore.commonData.ticketInfo || [])

watch(show, (value) => {
  if (value) {
    tagList.value = filterConditionsStore.filterTagListRef.get(props.isDetail) || []
  }
})

function onConfirm() {
  show.value = false
  filterConditionsStore.filterTagListRef.set(props.isDetail, tagList.value)
}

const tagOptions = computed(() => TicketOptions.value.map(option => option.attrTagList).flat())

const checkedLabel = computed(() => {
  return (filterConditionsStore.filterTagListRef.get(props.isDetail) || [])
    .map((item) => {
      return tagOptions.value.find(tag => tag.tagId.toString() === item.toString())?.tagName
    })
    .filter(Boolean)
    .join(', ')
})

const travellerTypesList = computed(
  () =>
    TicketOptions.value.find(item => item.tagTypeName === 'Traveller Types')?.attrTagList || [],
)

function isDisabeld(
  tagTypeName: string,
  item: {
    tagId: number
    tagName: string
  },
) {
  if (props.onlyRead) {
    return true
  }

  const selectedTravelerTypes = tagList.value.filter(tag =>
    travellerTypesList.value.some(attrTag => Number(attrTag.tagId) === Number(tag)),
  )

  return (
    (selectedTravelerTypes.length === 1
    && selectedTravelerTypes[0] === item.tagId
    && tagTypeName === 'Traveller Types')
    // 暂时关闭Family的选择
    || item.tagName === 'Family'
  )
}
</script>

<i18n lang="json">
{
  "en": {
    "title": "Select Tickets",
    "audit": "Adult",
    "child": "Child",
    "family": "Family",
    "familyDesc": "(1 or 2 adults with a child)",
    "Ticket types": "Ticket types",
    "Traveller Types": "Traveller types",
    "ticketOnly": "Ticket Only",
    "combo": "Combo Ticket",
    "apply": "Apply"
  }
}
</i18n>
