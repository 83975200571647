<template>
  <van-checkbox v-bind="attrs" :disabled="disabled">
    <slot></slot>
    <template #icon="props">
      <div
        v-if="props.disabled && !props.checked"
        class="h-6 w-6 flex items-center justify-center rounded-sm bg-[#777474]"
      ></div>
      <div v-else-if="props.checked" class="h-6 w-6 flex items-center justify-center rounded-sm bg-black">
        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 5.09091L5.94118 10L15 1" stroke="white" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div v-else class="box-border h-6 w-6 b-1 border-black rounded-sm border-solid"></div>
    </template>
  </van-checkbox>
</template>

<script setup lang="ts">
defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
})

const attrs = useAttrs()
</script>
